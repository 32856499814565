<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <div v-if="$store.state.isp.spinner" class="spinner_content">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner
          style="width: 5rem; height: 5rem"
          label="Loading..."
        ></b-spinner>
      </div>
    </div>
    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>
    <div v-if="$store.state.isp.app_balance">
      Balance: {{ $store.state.isp.app_balance }} TK
    </div>
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <AppRef></AppRef>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-dropdown />
      <!-- //user profile info -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.name ? user.name : "...." }}
            </p>
            <!-- <span class="user-status">Admin</span> -->
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          :to="{
            name: 'user.users_profile',
            params: { id: user.id },
          }"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          Profile
        </b-dropdown-item>
        <b-dropdown-item
          :to="{ name: 'FetherIcon' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          FetherIcon
        </b-dropdown-item>

        <b-dropdown-divider />
        <b-dropdown-item
          :to="{ name: 'action-histories' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="AlignCenterIcon" class="mr-50" />
          All History
        </b-dropdown-item>
        <b-dropdown-item
          :to="{ name: 'user-action-histories' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="AlignCenterIcon" class="mr-50" />
          My History
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click.prevent="logout"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import AppRef from "../../views/AppRef.vue";
import NotificationDropdown from "./NotificationDropdown.vue";

import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
export default {
  name: "SideNav",
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // Navbar Components
    DarkToggler,
    AppRef,
    NotificationDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    permissions() {
      return this.$store.state.isp.user.permission_list;
    },
    user() {
      return this.$store.state.isp.user.info;
    },
  },
  mounted() {
    this.$store.dispatch("get_app_net_balance");
  },
  created() {
    this.$store.dispatch("roleWisePermitions");
    this.$store.dispatch("userInfo");
    this.$store.dispatch("permission_list");
  },
  methods: {
    logout() {
      this.axios.post("logout").then((resp) => {
        if (resp.data.success) {
          this.$router.push("/login");
          this.$success_message(resp.data.message);
          localStorage.clear();
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
  },
};
</script>

<style>
.spinner_content {
  position: absolute;
  transform: translateY(100%);
  top: 350%;
  left: 40% !important;
}
</style>
