<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      {{ general_setting.copyright_left }}
    </span>
    <span class="float-md-right d-none d-md-block">
      {{ general_setting.copyright_right }}
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  computed: {
    general_setting() {
      return this.$store.state.isp.general_setting;
    },
  },

  components: {
    BLink,
  },
};
</script>
