data = [
  {
    title: "Home",
    route: "home",
    icon: "HomeIcon",
  },
  {
    title: "Vendor",
    route: "vendor",
    icon: "CircleIcon",
    children: [
      {
        icon: "CircleIcon",
        title: "Items",
        route: "vendors",
      },
      {
        icon: "CircleIcon",
        title: "Provider (Upstream)",
        route: "VendorProvidersUpstream",
      },
      {
        icon: "CircleIcon",
        title: "Provider (Downstream)",
        route: "VendorProviders",
      },
      {
        icon: "CircleIcon",
        title: "Invoice (receiveable)",
        route: "Vendor-invoice-receiveable",
      },
      {
        icon: "CircleIcon",
        title: "Invoice (payable)",
        route: "Vendor-invoice-payable",
      },
    ],
  },
  {
    title: "Managers",
    icon: "UserIcon",
    children: [
      {
        icon: "CircleIcon",
        title: "Manager List",
        route: "users",
      },
      {
        icon: "CircleIcon",
        title: "Roles",
        route: "role",
      },
      {
        icon: "CircleIcon",
        title: "Agents",
        route: "agents",
      },
    ],
  },
  {
    title: "HRM",
    icon: "UserIcon",
    children: [
      {
        icon: "CircleIcon",
        title: "Department List",
        route: "departments",
      },
      {
        icon: "CircleIcon",
        title: "Designation List",
        route: "designations",
      },
      {
        icon: "CircleIcon",
        title: "Employee List",
        route: "employees",
      },
      {
        icon: "CircleIcon",
        title: "Shift List",
        route: "workinghours",
      },
      {
        icon: "CircleIcon",
        title: "Attendace List",
        route: "attendance",
      },
      {
        icon: "CircleIcon",
        title: "Payroll",
        route: "payroll",
      },
      {
        icon: "CircleIcon",
        title: "Leave Management",
        route: "leave_management",
      },
    ],
  },
  {
    title: "Accounts",
    icon: "ShieldIcon",
    children: [
      {
        icon: "CircleIcon",
        title: "Category",
        route: "b-c-category",
      },
      {
        title: "Bill Collection",
        route: "bill-collection",
        icon: "CircleIcon",
      },
      {
        title: "Daily Income",
        route: "daily-income",
        icon: "CircleIcon",
      },
      {
        title: "Daily Expense",
        route: "daily-expense",
        icon: "CircleIcon",
      },
      {
        title: "Profit & Loss",
        route: "profit-loss",
        icon: "CircleIcon",
      },
    ],
  },
  {
    title: "User",
    icon: "UsersIcon",
    children: [
      {
        title: "Add User",
        route: "customerAdd",
        icon: "CircleIcon",
      },
      {
        title: "View Users",
        route: "homeCustomer",
        icon: "CircleIcon",
      },
      {
        title: "Corporate Users",
        route: "corporateCustomer",
        icon: "CircleIcon",
      },
      {
        title: "Pending Users",
        route: "pendingCustomer",
        icon: "CircleIcon",
      },
      {
        title: "Import Customers",
        route: "ImportsCustomers",
        icon: "CircleIcon",
      },
      {
        title: "New Registration",
        route: "newCustomerRegistration",
        icon: "CircleIcon",
      },
      {
        title: "Mikrotik Users",
        route: "user.mikrotik",
        icon: "CircleIcon",
      },
      {
        title: "Expiring Users",
        route: "expiring_customer",
        icon: "CircleIcon",
      },
      {
        title: "Expired Users",
        route: "expired_customer",
        icon: "CircleIcon",
      },
      {
        title: "Suspended Users",
        route: "suspendedCustomer",
        icon: "CircleIcon",
      },
      {
        title: "Deleted Users",
        route: "deletedCustomer",
        icon: "CircleIcon",
      },
      {
        title: "Online User",
        route: "deletedCustomer",
        icon: "CircleIcon",
        children: [
          {
            title: "Radius User",
            route: "",
            icon: "CircleIcon",
          },
          {
            title: "MikroTik User",
            route: "AllMikrotikUsers",
            icon: "CircleIcon",
          },
        ],
      },
    ],
  },
  {
    title: "Nas",
    icon: "ServerIcon",
    children: [
      {
        title: "Add Nas",
        route: "addNas",
        icon: "CircleIcon",
      },
      {
        title: "View Nas",
        icon: "CircleIcon",
        route: "ViewNas",
      },
      {
        title: "Maintenance",
        icon: "CircleIcon",
        route: "maintanence",
      },
      {
        title: "IP Pool",
        icon: "BarChart2Icon",
        children: [
          {
            title: "View IP Pool",
            route: "ippool",
            icon: "CircleIcon",
          },
          {
            title: "Public IP",
            route: "ippool.publicip",
            icon: "CircleIcon",
          },
        ],
      },
    ],
  },
  {
    title: "Ticket",
    icon: "FramerIcon",
    children: [
      {
        title: "View Ticket",
        route: "ticket",
        icon: "CircleIcon",
      },
      {
        title: "Ticket Category	",
        route: "TicketCategory",
        icon: "CircleIcon",
      },
    ],
  },
  {
    title: "Mikrotik ",
    icon: "CpuIcon",
    children: [
      {
        title: "Add Mikrotik",
        route: "addMikrotik",
        icon: "CircleIcon",
      },
      {
        title: "View Mikrotik",
        route: "mikrotik",
        icon: "CircleIcon",
      },
      {
        title: "MikroTik Ip Pool",
        route: "mikrotik-ip-pool",
        icon: "CircleIcon",
      },
      {
        title: "PPPoE Active Users",
        route: "PPoE-active-user",
        icon: "CircleIcon",
      },
      {
        title: "Public IP",
        route: "mikrotik.publicip",
        icon: "CircleIcon",
      },
      {
        title: "Mikrotik Users",
        route: "mikrotik_user",
        icon: "CircleIcon",
      },
      {
        title: "Queue",
        icon: "CircleIcon",
        children: [
          {
            title: "View All Simple Queue",
            route: "queue",
            icon: "CircleIcon",
          },
          {
            title: "View All Queue Type",
            route: "queue-type",
            icon: "CircleIcon",
          },
        ],
      },
      {
        title: "Interface",
        icon: "CircleIcon",
        children: [
          {
            title: "View Interface",
            route: "interfaces",
            icon: "CircleIcon",
          },
          {
            title: "ARP",
            route: "interfaces.arp",
            icon: "CircleIcon",
          },
          {
            title: "Addresses",
            route: "interfaces.addresses",
            icon: "CircleIcon",
          },
          {
            title: "Ethernet",
            route: "interfaces.ethernet",
            icon: "CircleIcon",
          },
        ],
      },
    ],
  },
  {
    title: "Packages",
    icon: "NavigationIcon",
    children: [
      {
        title: "View Packages",
        route: "Packages",
        icon: "CircleIcon",
      },
      {
        icon: "PlusCircleIcon",
        title: "Add Package",
        route: "addpackage",
      },
      {
        title: "Franchise Package",
        route: "FranchisePackge",
        icon: "CircleIcon",
      },
      {
        title: "Pop Package",
        route: "PopPackge",
        icon: "CircleIcon",
      },
    ],
  },
  {
    title: "NetWork",
    icon: "WifiIcon",
    children: [
      {
        title: "POP",
        route: "pop",
        icon: "CircleIcon",
      },
      {
        title: "Franchise",
        route: "Franchise",
        icon: "CircleIcon",
      },
      {
        title: "Zone",
        route: "zone",
        icon: "CircleIcon",
      },
      {
        title: "FTTX ",
        icon: "WifiIcon",
        children: [
          {
            title: "OLT",
            route: "olt",
            icon: "CircleIcon",
          },
          {
            title: "Brand",
            route: "brands",
            icon: "CircleIcon",
            icon: "CircleIcon",
          },
          {
            title: "ONU",
            route: "onu",
            icon: "CircleIcon",
            icon: "CircleIcon",
          },
          {
            title: "VLAN",
            route: "vlan",
            icon: "CircleIcon",
            icon: "CircleIcon",
          },
        ],
      },
      {
        title: "District",
        route: "district",
        icon: "CircleIcon",
      },
      {
        title: "Upazila",
        route: "Upazila",
        icon: "CircleIcon",
      },
      {
        title: "Network Diagram",
        icon: "WifiIcon",
        children: [
          {
            title: "Monitor Network",
            route: "NetworkMonitor",
            icon: "CircleIcon",
          },
          {
            title: "Box",
            route: "box",
            icon: "CircleIcon",
          },
          {
            title: "TJB",
            route: "tjb",
            icon: "CircleIcon",
          },
        ],
      },
    ],
  },
  {
    title: "Reports",
    icon: "ClipboardIcon",
    children: [
      {
        title: "BTRC Report",
        route: "BtrcReport",
        icon: "CircleIcon",
      },
    ],
  },
  {
    title: "Billing ",
    icon: "DollarSignIcon",
    children: [
      {
        title: "Invoices",
        route: "invoices",
        icon: "CircleIcon",
      },
      {
        title: "Received Payment",
        route: "receivedPayment",
        icon: "CircleIcon",
      },
      {
        title: "Prepaid Payment",
        route: "prepaidPayment",
        icon: "CircleIcon",
      },
      {
        title: "Postpaid Payment",
        route: "postpaidPayment",
        icon: "CircleIcon",
      },
      {
        title: "Franchise Billing",
        route: "franchiseBilling",
        icon: "CircleIcon",
      },
      {
        title: "Corporate Billing",
        route: "corporateBilling",
        icon: "CircleIcon",
      },
      {
        title: "Due Invoice",
        route: "DueInvoice",
        icon: "CircleIcon",
      },
      {
        title: "Collectable Bills",
        route: "bill_collectabl_users",
        icon: "CircleIcon",
      },
    ],
  },
  {
    title: "SMS",
    icon: "MessageSquareIcon",
    children: [
      // {
      //   title: "SMS Balance",
      //   route: "sms.balance",
      //   icon: "CircleIcon",
      // },
      {
        title: "SMS Template",
        route: "sms.template",
        icon: "CircleIcon",
      },
      {
        title: "Send SMS",
        route: "sms.send_message",
        icon: "CircleIcon",
      },
      {
        title: "SMS Report",
        route: "sms.report",
        icon: "CircleIcon",
      },
      {
        title: "E-Mail Template",
        route: "email.template",
        icon: "CircleIcon",
      },
      {
        title: "Send Email",
        route: "",
        icon: "CircleIcon",
      },
      {
        title: "SMS API Settings",
        route: "ApiSettings",
        icon: "CircleIcon",
      },
      {
        title: "Email API Settings",
        route: "EmailApi",
        icon: "CircleIcon",
      },
      {
        title: "Sent Messages",
        route: "sms.sent-message",
        icon: "CircleIcon",
      },
    ],
  },
  {
    title: "Setting",
    icon: "ToolIcon",
    children: [
      {
        title: "General Setting",
        route: "settingGeneral",
        icon: "CircleIcon",
      },
      {
        // multiple add billing cicle
        title: "Software Setting",
        route: "softwareSetting",
        icon: "CircleIcon",
      },
      {
        title: "System Setting",
        route: "systemSettings",
        icon: "CircleIcon",
      },
    ],
  },
  {
    title: "Inventory",
    icon: "CoffeeIcon",
    children: [
      {
        title: "Categories",
        route: "inventory_categories",
        icon: "CircleIcon",
      },
      {
        title: "Brands",
        route: "inventory_brands",
        icon: "CircleIcon",
      },
      {
        title: "Units",
        route: "inventory_units",
        icon: "CircleIcon",
      },
      {
        title: "Suppliers",
        route: "inventory_suppliers",
        icon: "CircleIcon",
        // children: [
        //   {
        //     title: "All",
        //     icon: "CircleIcon",
        //   },
        // ]
      },
      {
        title: "Products",
        route: "inventory_products",
        icon: "CircleIcon",
      },
      {
        title: "Purchases",
        route: "inventory_purchases",
        icon: "CircleIcon",
      },
      {
        title: "Product Return",
        route: "inventory_product_return",
        icon: "CircleIcon",
      },
      {
        title: "Supplier Ledger",
        route: "supplier_ledger",
        icon: "CircleIcon",
      },
    ],
  },
  {
    title: "Network Manager",
    icon: "ActivityIcon",
    children: [
      {
        title: "Nttn",
        route: "nm_nttns",
        icon: "CircleIcon",
      },
      {
        title: "Brand",
        route: "nm_brands",
        icon: "CircleIcon",
      },
      {
        title: "Enclouser Setting",
        route: "nm_enclouser_settings",
        icon: "CircleIcon",
      },
      {
        title: "Splitter Setting",
        route: "nm_splitter_settings",
        icon: "CircleIcon",
      },
      {
        title: "Fiber Setting",
        route: "nm_fiber_settings",
        icon: "CircleIcon",
      },
      {
        title: "Pop",
        route: "nm_pops",
        icon: "CircleIcon",
      },
      {
        title: "Rack",
        route: "nm_racks",
        icon: "CircleIcon",
      },
      {
        title: "Router",
        route: "nm_routers",
        icon: "CircleIcon",
      },
      {
        title: "Switch",
        route: "nm_switches",
        icon: "CircleIcon",
      },
      {
        title: "Transmission Equipment",
        route: "nm_transmission_equipments",
        icon: "CircleIcon",
      },
      {
        title: "ODF",
        route: "nm_odfs",
        icon: "CircleIcon",
      },
      {
        title: "OLT",
        route: "nm_olts",
        icon: "CircleIcon",
      },
      {
        title: "Splitter",
        route: "nm_splitters",
        icon: "CircleIcon",
      },
      {
        title: "Enclouser",
        route: "nm_enclousers",
        icon: "CircleIcon",
      },
      {
        title: "Fiber",
        icon: "GitCommitIcon",
        children: [
          {
            title: "Fiber",
            route: "nm_fibers",
            icon: "CircleIcon",
          },
          {
            title: "Fiber Tubes",
            route: "nm_fiber_tubes",
            icon: "CircleIcon",
          },
          {
            title: "Fiber Cores",
            route: "nm_fiber_cores",
            icon: "CircleIcon",
          },
        ],
      },
      {
        title: "Ports",
        icon: "GitPullRequestIcon",
        children: [
          {
            title: "Router Ports",
            route: "nm_router_ports",
            icon: "CircleIcon",
          },
          {
            title: "Switch Ports",
            route: "nm_switch_ports",
            icon: "CircleIcon",
          },
          {
            title: "ODF Ports",
            route: "nm_odf_ports",
            icon: "CircleIcon",
          },
          {
            title: "OLT Ports",
            route: "nm_olt_ports",
            icon: "CircleIcon",
          },
          {
            title: "Transmission Equipment Ports",
            route: "nm_transmission_equipment_ports",
            icon: "CircleIcon",
          },
          {
            title: "Pivot Enclouser Fiber Core",
            route: "nm_pivot_enclouser_fiber_core",
            icon: "CircleIcon",
          },
          {
            title: "Pivot Fiber Core Splitter",
            route: "nm_pivot_fiber_core_splitter",
            icon: "CircleIcon",
          },
        ],
      },
      {
        title: "Map",
        route: "nm_map",
        icon: "CircleIcon",
      },
    ],
  },
];

module.exports = data;
