<template>
  <div>
    <button @click.prevent="RefSystem()" class="btn">
      <feather-icon size="16" icon="RefreshCcwIcon" class="mr-50" />
    </button>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "AppRef",
  components: {},
  methods: {
    RefSystem() {
      this.$store.dispatch("spinnerLoading", true);
      axios.get("system/refresh").then((resp) => {
        if (resp.data.success) {
          this.$s_tost(resp.data.message);
          window.location.reload();
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      }).finally(() => {
        this.$store.dispatch("spinnerLoading", false);
      });
    },
  },
};
</script>
